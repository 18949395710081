
document.getElementById("send").addEventListener("click", validateForm);
function validateForm() {
    const formError = document.getElementById("formError");
    formError.innerHTML = "";
    const nombre = document.getElementById("nombre").value;
    const email = document.getElementById("email").value;
    const telefono = document.getElementById("telefono").value;
    const mensaje = document.getElementById("mensaje").value;
    if(nombre.trim() == ""){
        formError.innerHTML = "El campo nombre es obligatorio";
    } else if(email.trim() == "" && telefono.trim() === ""){
        formError.innerHTML = "El campo email o telefono es obligatorio";
    } else if(mensaje.trim() == ""){
        formError.innerHTML = "El campo mensaje es obligatorio";
    } else{
        try {
            sendMessage(nombre, email, telefono,  mensaje);
            formError.innerHTML = "Mensaje enviado";
        } catch (error) {
            console.log(error)
            formError.innerHTML = "Error al enviar el mensaje";
        }
    }
}

async function sendMessage(nombre, email, telefono,  mensaje) {
    const url = "https://europe-west1-fruteria-ignacia.cloudfunctions.net/sendMail";
    const result = await fetch(url + `?nombre=${nombre}&email=${email}&telefono=${telefono}&mensaje=${mensaje}`)
    return result
}


function getCookie(c_name){
    var c_value = document.cookie;
    var c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start == -1){
        c_start = c_value.indexOf(c_name + '=');
    }
    if (c_start == -1){
        c_value = null;
    }else{
        c_start = c_value.indexOf('=', c_start) + 1;
        var c_end = c_value.indexOf(';', c_start);
        if (c_end == -1){
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
}
function setCookie(c_name,value,exdays){
    var exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value=escape(value) + ((exdays==null) ? '' : '; expires='+exdate.toUTCString());
    document.cookie=c_name + '=' + c_value;
}

document.addEventListener('click', function (event) {
    if (!event.target.matches('.okCookies')) return;
    aceptarCookies();
	event.preventDefault();
}, false);

function aceptarCookies() {
    setCookie('cookies','1',365);
    document.getElementById('cookies').style.display='none';
}

function comprobarCookies(){
    var cookies = getCookie('cookies');
    if(cookies == '1' ){
        document.getElementById('cookies').style.display='none';
    }else{
        document.getElementById('cookies').style.display='block';
    }
}

window.onload = function(){
    comprobarCookies();
}